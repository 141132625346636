import React , {useState} from 'react';
import './App.css';
import Login from "./login/Login"
import Messenger from "./messenger/Messenger";
import Guide from "./guide/Guide";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  const [password, setPassword] = useState(null);

  return (

    <Router>
    <div className="App">
      <Switch>
        <Route path="/login">
          <Login onChange={(password: any) => setPassword(password)}/>
        </Route>
        <Route path="/guide">
          <Guide/>
        </Route>
        <Route path="/">
          <Messenger password={password}/>
        </Route>
      </Switch>
    </div>
  </Router>

  );
}

export default App;
