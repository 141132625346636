import React, { useState, useEffect } from 'react';
import { Redirect, Link } from "react-router-dom";
import "./Messenger.css";

function Messenger(password: any) {
    console.log(password);

    const [currentDisplay, setCurrentDisplay] = useState("");
    const [newMessage, setNewMessage] = useState("");

    // const apiUri = "https://localhost:5001";
    const apiUri = "https://mumcomapi.petegosling.com";

    useEffect(() => getCurrentDisplay());

    if (!password.password) {
        console.log("Redirecting from Messenger to /login");
        return (
            <Redirect
                to={{
                    pathname: "/login"
                }}
            />
        )
    }

    console.log("Messenger continues with password", password);

    function sendMessage() {
        fetch(apiUri, {
            method: 'PUT',
            headers: {
                "content-type": "text/raw",
                "authorization": getPassword()
            },
            body: newMessage
        }).then(() => {
            getCurrentDisplay();
        });
    }

    function clear() {
        setNewMessage("");

        fetch(apiUri, {
            method: 'DELETE',
            headers: {
                "content-type": "text/raw",
                "authorization": getPassword()
            }
        }).then(() => {
            getCurrentDisplay();
        });
    }

    function getCurrentDisplay() {
        if (password.password) {
            fetch(apiUri, {
                headers: {
                    "content-type": "text/raw",
                    "authorization": getPassword()
                },
            }).then((response) => {
                response.text().then((text) => {
                    setCurrentDisplay(text);
                })
            });
        }
    }

    function getPassword() {
        return password.password;
    }

    return (
        <div className="Messenger">
            <div className="DisplayClear">
                <label>Current Display</label>
                <div className="Display">
                    {currentDisplay}
                </div>
            </div>
            <div className="MessageSend">
                <label>New Message</label>
                <textarea rows={4} cols={20} placeholder="Enter your message here" value={newMessage} onChange={(e) => setNewMessage(e.target.value)}></textarea>
                <span className="buttons">
                    <button id="clear" onClick={(e) => clear()}>Clear</button>
                    <Link className="link" to="/guide"><button id="help">Help</button></Link>
                    <button id="send" onClick={(e) => sendMessage()}>Send</button>
                </span>
            </div>

        </div>

    );
}

export default Messenger;
