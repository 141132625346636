import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import "./login.css";

function Login(props: any) {
    console.log("Login starts");

    const greeting = 'MumCom Login';

    const [ready, setReady] = useState(false);
    const [password, setPassword] = useState("");

    function login() {
        setReady(true);
        props.onChange(password)

    }

    if (ready) {
        console.log("Redirecting from login to /");
        return <Redirect to='/' />
    }

    console.log("Login continues");

    return (
        <div className="login">
            <h1>{greeting}</h1>
            <label>Password</label>
            <input type="password" onChange={(e) => setPassword(e.target.value)}></input>
            <button onClick={(e) => login()}>Login</button>
        </div>
    )

}

export default Login;