import React, { useState, useEffect } from 'react';
import { Redirect, Link } from "react-router-dom";
import "./Guide.css";

function Guide() {
    return (
        <div className="Guide">
            <h3>Recommended Use</h3>
            <p>First, open the <a className="link" href="https://home.nest.com/camera/8acf102485f04d25b9fe2a079625d15d" target="Nest Camera">Nest Camera site</a> so
             you can see if mum is sitting in the living room.</p>
            
            <p>Send a hello message.</p>

            <p>To attract mum's attention to the the display, you can phone her using her landline. After she picks up, and you see her looking at the display, you can hang up.</p>

            <p>Send more messages. Mum usually speaks after reading each message. Give her time to read each one. 
                She cannot see or scroll back through earlier messages.</p>

            <p>Mum usually says bye at the end. Please press the 'Clear' button at the end, to leave the display blank. 
                Mum assumes you have gone when the display goes blank.
            </p>

            <Link className="link" to="/"><button>MumCom Display</button></Link>
            
        </div>
    )


}

export default Guide;
